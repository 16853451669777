import React, { useEffect, useCallback } from 'react';
import anime from 'animejs';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import type { Engine } from 'tsparticles-engine';

const App: React.FC = () => {
  useEffect(() => {
    anime
      .timeline({
        easing: 'easeOutExpo',
      })
      .add({
        targets: '#welcome',
        opacity: [1, 1],
        translateY: [0, 0],
        duration: 1000,
      })
      .add({
        targets: ['#message1', '#message2'],
        opacity: [0, 1],
        translateY: [0, 0],
        duration: 1000,
        delay: anime.stagger(300),
      })
      .add({
        targets: ['#message3'],
        opacity: [0, 1],
        translateY: [0, 0],
        duration: 1000,
        delay: anime.stagger(300),
      })
      .add({
        targets: '#message4',
        scale: [0, 1],
        duration: 200,
        easing: 'easeOutSine',
        direction: 'alternate',
        delay: 0
      });

  }, []);

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  return (
    <div className='h-screen w-screen flex items-center justify-center font-sans overflow-hidden text-text-dark'>
      <Particles
        id='particles-js'
        init={particlesInit}
        options={{
          particles: {
            number: { value: 50, density: { enable: true, value_area: 1000 } },
            color: { value: '#99f6e4' },
            shape: { type: 'circle', stroke: { width: 0, color: '#000000' } },
            opacity: { value: 0.4, random: false, anim: { enable: true, speed: 0.2, opacity_min: 0.1, sync: false } },
            size: { value: 4, random: true, anim: { enable: true, speed: 2, size_min: 0.1, sync: false } },
            line_linked: { enable: false },
            move: {
              enable: true,
              speed: 0.3,
              direction: 'none',
              random: false,
              straight: false,
              out_mode: 'out',
              bounce: true,
              attract: { enable: false, rotateX: 600, rotateY: 1200 },
            },
          },
          retina_detect: true,
        }}
        className='absolute inset-0 z-0'
      />
      <div className='text-center z-10 p-4 sm:p-8 rounded-xl'>
        <h1 id='welcome' className='text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-semibold mb-4 sm:mb-8 opacity-0 gradient-text-2'>
          Welcome to pinyin.io
        </h1>
        <div className='mt-4 space-y-4 sm:space-y-4'>
          <p id='message1' className='text-lg sm:text-xl md:text-2xl opacity-0 gradient-text sub-text'>
            A freshly new pinyin dictionary...
          </p>
          <br />
          <p id='message2' className='text-lg sm:text-xl md:text-2xl opacity-0 gradient-text sub-text'>
            and learning application coming soon.
          </p>
          <br />
        </div>
        <div className='mt-8'>
          <p id='message3' className='text-lg sm:text-xl md:text-2xl opacity-0 gradient-text sub-text'>
            The release is planned for{' '}
          </p>
          <p id='message4' className='pl-2 text-lg font-bold sm:text-xl md:text-2xl opacity-0 gradient-text-3 sub-text'>
            Q1/2025</p>
          <p id='message4' className='pl-2 text-lg sm:text-xl md:text-2xl opacity-0 animate-pulse gradient-text sub-text'>
            🚀
          </p>
        </div>
      </div>
    </div>
  );
};

export default App;
